<template>
  <div class="new-account-book">
    <h2 class="title">{{ $t('accountBooks.newTitle') }}</h2>

    <div v-if="error" class="notification is-danger">
      {{ error }}
    </div>

    <account-book-form
      :account-book="accountBook"
      :submit-callback="create"
    />
  </div>
</template>

<script>
import AccountBookForm from './AccountBookForm'

export default {
  name: 'new-transaction',
  components: {
    AccountBookForm
  },
  data () {
    return {
      transaction: null,
      error: null
    }
  },
  created () {
    this.clearAccountBook()
  },
  methods: {
    async create () {
      try {
        const accountBook = await this.$store.dispatch('accountBooks/create', this.accountBook)

        this.$router.push(`/${accountBook.slug}/transactions`)
      } catch (error) {
        this.error = error.response.data.message
      }
    },
    clearAccountBook () {
      this.accountBook = {
        name: null
      }
    }
  }
}
</script>

<style scoped>

</style>
