<template>
  <form @submit.prevent="submitCallback">
    <input-group
            :label="$t('accountBooks.form.name')"
            name="name"
            v-model="accountBook.name"
            icon="fas fa-book-open"
            required
    />

    <button type="submit" class="button is-primary">{{ $t('common.submit')}}</button>
  </form>
</template>

<script>
import InputGroup from '../basic/InputGroup'

export default {
  name: 'account-book-form',
  components: { InputGroup },
  props: {
    accountBook: {
      type: Object,
      required: true
    },
    submitCallback: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
